<template>
  <div>
    <!--begin::Documents-->
    <div class="card card-custom gutter-b">
      <div class="card-header pt-3 pb-3">
        <div class="card-title">
          <h3 class="card-label">Document List</h3>
        </div>
        <div class="card-toolbar" v-if="user_permissions.includes('chapter_store_imp_doc') || user_permissions.includes('se_store_imp_doc')">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <a
                @click="redirectToCreateDoc()"
                class="btn btn-primary font-weight-bolder"
              >
                <span class="svg-icon svg-icon-md">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg-->
                  <i
                    style="/! color: #fff; /margin-right: 5px;font-weight: bold;"
                    class="flaticon2-file-1"
                  />
                  <!--end::Svg Icon-->
                </span>
                Add Document
              </a>
              <!-- <button
                type="button"
                class="v-btn v-btn--contained theme--dark v-size--default primary"
              >
                <span class="v-btn__content" @click="redirectToCreateDoc()">
                  Add Document
                </span>
              </button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_document_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-card-title class="pt-0 pr-0">
                <v-spacer></v-spacer>
                <div class="col-md-4 my-2 my-md-0 pr-0">
                  <div class="input-icon">
                    <v-text-field
                      v-model="search"
                      single-line
                      placeholder="Search"
                      class="form-control"
                      hide-details
                    ></v-text-field>
                    <span>
                      <i class="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                </div>
              </v-card-title>
              <v-data-table :headers="headers" :items="document_list">
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.name }}</td>
                    <td>{{ row.item.doc_type }}</td>
                    <td>{{ row.item.renewal_date || "-" }}</td>
                    <td>
                      <v-tooltip top v-if="user_permissions.includes('chapter_store_imp_doc') || user_permissions.includes('se_store_imp_doc')">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="documentEdit(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Document</span>
                      </v-tooltip>
                      <v-tooltip top v-if="user_permissions.includes('chapter_store_imp_doc') || user_permissions.includes('se_store_imp_doc')">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="documentDelete(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete Document</span>
                      </v-tooltip>
                      <v-tooltip top v-if="(user_permissions.includes('chapter_renewal_reminder') || user_permissions.includes('se_renewal_reminder')) && row.item.renewal_date != NULL">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="documentRecycle(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-recycle</v-icon>
                          </v-btn>
                        </template>
                        <span>Renew Document</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </form>
      </div>
    </div>
    <!--end::Documents-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { DELETE_DOCUMENT } from "@/core/services/store/document.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "documentsList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      user_permissions: [],
      document_list: [],
      search: "",
      headers: [
        { text: "Name", align: "left", value: "name", width: "25%" },
        { text: "Document Type", value: "doc_type", width: "25%" },
        { text: "Renewal Date", value: "renewal_date", width: "25%" },
        { text: "Action", sortable: false, value: "action", align: "center" }
      ]
    };
  },
  watch: {
    search: function(newVal) {
      this.getDocumentsList(newVal);
    },
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["stateDocumentsList", "stateDocumentObj","getUserPermissions"]),
    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Documents" }]);
    this.getDocumentsList();
    this.$store.dispatch(GET_COMPANY);
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    documentDelete(doc_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Document file!",
        showCancelButton: true,
        confirmButtonText: "Delete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_DOCUMENT, doc_id).then(() => {
            this.getDocumentsList();
          });
        } else if (result.isDismissed) {
          Swal.fire("Document File is safe.", "", "info");
        }
      });
    },
    redirectToCreateDoc() {
      this.$router.push({ name: "documents" });
    },
    getDocumentsList(search_str = "") {
      // this.$store.dispatch(GET_DOCUMENT_LIST).then(() => {
      //   this.document_list = this.stateDocumentsList;
      // });
      let context = this;
      let api_url = "document";
      if (search_str) {
        api_url = "document?search_str=" + search_str;
      }
      axios({
        method: "get",
        url: api_url,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.document_list = result.data.documents;
        },
        function() {
          context.getDocumentsList();
          // console.log(error);
        }
      );
    },
    documentEdit(doc_id) {
      this.$router.push({ name: "documents.edit", params: { id: doc_id } });
      // this.$store.dispatch(GET_DOCUMENT_BY_ID, doc_id).then(() => {
      //   this.$router.push({ name: "documents.edit", params: { id: doc_id } });
      // });
    },
    documentRecycle(doc_id) {
      this.$router.push({ name: "documents.renew", params: { id: doc_id } });
    }
  }
};
</script>
